import { useRoutes } from "react-router-dom";
import { Main } from "../pages/main/main";

const routes = [
  {
    path: "/",
    element: <Main/>
  }
]

export const Routes = () => {
  const elements = useRoutes(routes)
  return elements
}