import { useTranslation } from "react-i18next";
import "./about-us.scss";
import { about } from "../../data";
import telegram from "../../assets/images/telegram.svg";
import youtube from "../../assets/images/youtube.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
 


export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <section id="about-us" className="container about-us">
      <h2 className="text-center about-us__title ">{t("about_us")}</h2>
      <div className="row">
      {about.map((value, id) => (
            <div key={id} className="col-12 col-md-6">
              <p className="fs-3 fw-bold about-us__numbers">
                0{t(`${value.id}`)}
              </p>
              <p className="about-us__text">{t(`${value.text}`)}</p>
            </div>
          ))}
      </div>

      <div className="d-flex justify-content-center my-5 g-5 gap-3">
            <div>
              <a href="https://t.me/clveluz">
                <img src={telegram} alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/clvel.uz/">
                <img src={instagram} alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/profile.php?id=&mibextid=LQQJ4d">
                <img src={facebook} alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/@Clveluz">
                <img src={youtube} alt="" />
              </a>
            </div>
          </div>
    </section>
  );
};
