export const about = [
  {
    id: 1,
    text: "about-us1"
  },
  {
    id: 2,
    text: "about-us2"
  },
  {
    id: 3,
    text: "about-us3"
  },
  {
    id: 4,
    text: "about-us4"
  },
  {
    id: 5,
    text: "about-us5"
  },
  {
    id: 6,
    text: "about-us6"
  },
]
