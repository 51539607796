import "./charter-flights.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import globe from "../../assets/images/globe.svg";
import airplane from "../../assets/images/airplane.svg";
import departure from "../../assets/images/departure.png";
import { useTranslation } from "react-i18next";

// import "../../../node_modules/styles.css";

export const CharterFlights = () => {
  const { t } = useTranslation();
  return (
    <section className="container swiper-section">
      <h2 className="swiper-section__title">{t("choose_charter")}</h2>

      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="swiper_content d-flex flex-column swiper__item">
            <img className="s-image" src={departure} alt="" />
            <h3 className="swiper-section__item-title my-3">
              {t("flights_per_year")}
            </h3>
            <p className="swiper-section__item-text">
              {t("flights_per_year_text")}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_content swiper_content--globe  d-flex flex-column swiper__item">
            <img className="s-image" src={globe} alt="" />
            <h3 className="swiper-section__item-title my-3">
              {t("global_coverage")}
            </h3>
            <p className="swiper-section__item-text">
              {t("global_coverage_text")}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_content d-flex flex-column swiper__item">
            <img className="s-image" src={airplane} alt="" />
            <h3 className="swiper-section__item-title my-3">{t("aircraft")}</h3>
            <p className="swiper-section__item-text">{t("access_aircraft")}</p>
          </div>
        </SwiperSlide>
      </Swiper>


      
      <h2 className="charter-advantages">{t("charter-advantages")}</h2>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
               #1
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
             {t("advantage1")}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
               #2
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            {t("advantage2")}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
               #3
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
             {t("advantage3")}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
               #4
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
             {t("advantage4")}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
               #5
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
             {t("advantage5")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
