import "./contacts.scss";
import email from "../../assets/images/email.svg";
import phone from "../../assets/images/phone.svg";
import location from "../../assets/images/location.svg";
import { useTranslation } from "react-i18next";
// import emailjs from "@emailjs/browser"
import { useRef } from "react";

export const Contacts = () => {
  const { t } = useTranslation();
  const form = useRef()

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_1t84smp', 'template_94bld01', form.current, 'HpP-ssRINlcUpW8RL')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  //     e.target.reset()
  // };


  return (
    <section id="contacts" className="contacts container">
      <h2 className="contacts_title text-center">
        {t("nav_contacts")}
      </h2>
      <div className="d-flex flex-md-row flex-column-reverse justify-content-center align-items-center">
        <div className="me-lg-5 me-md-2 me-0 mt-md-0 mt-5">
          <iframe
            className="contacts_maps"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d749.668873023608!2d69.25850026957042!3d41.27240100615756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE2JzIwLjYiTiA2OcKwMTUnMzIuOSJF!5e0!3m2!1sru!2s!4v1675493678592!5m2!1sru!2s"
            width="600"
            height="450"
            title="cloud travel"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="ms-lg-5 ms-md-2 ms-0 mb-md-0 mb-5">
          <p className="d-flex gap-3">
            <img
              className="contacts_email d-block "
              src={email}
              alt="email cloud travel"
            />
            <a
              className="contacts_text text-black d-block link-offset-2 link-underline link-underline-opacity-0"
              href="mailto:cloudtravel@gmail.com"
            >
              jetpro.uz@gmail.com
            </a>
          </p>
          <p className="d-flex gap-3">
            <img
              className="contacts_email d-block "
              src={phone}
              alt="email cloud travel"
            />
            <a
              className="d-block contacts_text text-black link-offset-2 link-underline link-underline-opacity-0"
              href="tel:+998900000000"
            >
              90 0000000
            </a>
          </p>
          <p className="contacts_text d-flex gap-3">
            <img
              className="contacts_email d-block "
              src={location}
              alt="email cloud travel"
            />{" "}
            {t("contacts_address")}
          </p>
          <form ref={form} >
            <div className="form-floating mt-5 mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="user_name"
                required
              />
              <label htmlFor="floatingInput">{t("name")}</label>
            </div>
            <div className="form-floating">
              <input
                type="number"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="user_phone"
                required
              />
              <label htmlFor="floatingPassword">{t("phone")}</label>
            </div>
            <div className="col-auto d-grid mt-4">
              <button type="submit" className="btn btn-primary">
                {t("send_btn")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
