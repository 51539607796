import { AboutUs, CharterFlights, Contacts, Header } from "../../components"

export const Main = () => {
  return (
    <>
    <Header/>
    <AboutUs/>
    <CharterFlights/>
    <Contacts/>
    </>
  )
}